import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Jonnie Hallman on Refactoring Code",
  "date": "2015-01-06T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I love how Jonnie, aka `}<a parentName="p" {...{
        "href": "https://twitter.com/destroytoday"
      }}>{`@destroytoday`}</a>{`, has been writing a ton about his process as he’s building Cushion. `}<a parentName="p" {...{
        "href": "http://cushionapp.com/journal/2015-01-06/"
      }}>{`This post on refactoring`}</a>{` especially resonated with me.`}</p>
    <blockquote>
      <p parentName="blockquote">{`I’m about halfway there because a few days in, I realized if I wanted to do this right, I would need to rewrite the schedule timeline. I originally wrote it for the budget, then made it work for scheduling, redesigned it a few times, added a few more elements, and rigged it to somehow work with invoices. I was left with a monster that looked nice, but felt like a long-running Jenga game.`}</p>
    </blockquote>
    <p>{`This definitely happens to me all the time. When I write markup I always think I’m doing a good job at making it semantic, reusable and as simple as possible. But then, inevitably, design changes happen and I sort of hack it on top of the current markup structure. And soon enough it goes out of hand and I refactor. What’s great about refactoring though is that it’s like taking an exam for the second time, with all the answers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      